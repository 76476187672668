<template>
  <Page full fixed>
    <files-body
      :filters="{
        parent_file_id: `company-${$store.state.session.company.company_id}`
      }"
    />
  </Page>
</template>

<script>
import FilesBody from '../bodies/Files.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  components: { FilesBody }
}
</script>
